'use client'

import { createContext, useContext, useEffect, useState } from "react"

export interface ITemplate {
    id: string
    options: IOption[]
    headline?: string
    description?: string
}

export interface IOption {
    id: string
    displayName: string
    translated: { displayName: string, description?: string, placeholder?: string }
    required: boolean
    type: string
    typeProperties: { [key: string]: string }
    description?: string
    placeholder?: string
    values: { id: string, displayName: string, translated: { displayName: string } }[]
}


const ProductCustomizedContext = createContext<any>(undefined)

export function useProductCustomizedContext() {
    return useContext(ProductCustomizedContext)
}

const ProductCustomizedProvider = ({ children, template: propsTemplate }: { children: React.ReactNode, template: ITemplate }) => {
    let [template, setTemplate] = useState<ITemplate>(propsTemplate)

    let [stepOneOptions, setStepOne] = useState<IOption[]>([])
    let [stepTwoOptions, setStepTwo] = useState<IOption[]>([])

    let [values, setValues] = useState<{ [key: string]: string }>({})

    useEffect(() => setTemplate(propsTemplate), [propsTemplate])

    useEffect(() => {
        setStepOne(template.options?.filter(({ type }) => 'select' === type))
        setStepTwo(template.options?.filter(({ type }) => 'select' !== type))
    }, [template])

    const isNextAllowed = () => {
        let isAllowed = true
        stepOneOptions.forEach(({ id, required }) => {
            if (true === required && undefined === values[id]) {
                isAllowed = false
            }
        })
        return isAllowed
    }

    const isAddToCartAllowed = () => {
        let isAllowed = true
        template?.options?.forEach(({ id, required }) => {
            if (true === required && undefined === values[id]) {
                isAllowed = false
            }
        })
        return isAllowed
    }

    return (
        <ProductCustomizedContext.Provider value={{
            template,

            stepOneOptions,
            stepTwoOptions,

            values,
            setValues,

            isNextAllowed,
            isAddToCartAllowed
        }}>
            {children}
        </ProductCustomizedContext.Provider>
    )
}

export default ProductCustomizedProvider
